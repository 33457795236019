const staffCategoryData = {
    data: [
        {
            id: "ca361bf6a73811edafa10242ac120002",
            sequence: 1,
            category_name: "Our Veterinarians"
        },
        {
            id: "b450410aa61f11ed8a30207bd355214e",
            sequence: 2,
            category_name: "Our Nurses"
        },
        {
            id: "d650410aa61f11ed8a30207bd355214e",
            sequence: 3,
            category_name: "Our Technicians"
        },
        {
            id: "e650410aa61f11ed8a30207bd355214e",
            sequence: 4,
            category_name: "Our Staff"
        }
    ]
};

export default staffCategoryData;