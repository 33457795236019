/**
 * Formats the phone number as the user types in a form field.
 * Clears errors on the specific field once valid.
 *
 * @param {Object} input phone number input field from form
 * @param {Function} clearErrors clears input field errors
 *
 * @returns {string} formatted phone number for display
 */

export default (input, clearErrors) => {
    let { value } = input.target;
    let phone = value.replace(/[\s#-)(+]/g, ""); // strip out whitespace, parentheses, plus sign
    let matrix = "(###) ### ####";
    
    if (phone.length > 10) {
        matrix = "+###############";
    }

    let i = 0,
        val = value.replace(/\D/g, ""); //removes any non-numerical characters

    value = matrix.replace(/(?!\+)./g, (a) => {
        // looks for any character followed by the # sign
        if (/[#\d]/.test(a) && i < val.length) {
            return val.charAt(i++);
        }
        // replaces the # sign in matrix with empty string
        if (i >= val.length) {
            return "";
        }
        return a;
    });
    input.target.value = value || null;
    if (phone.length > 9) {
        clearErrors(input.target.name);
    }
    return value;
};