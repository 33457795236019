import React from "react";

import { Accordion, Container, Row, Col } from "react-bootstrap";
import faqData from "../../util/staticData/faq.data";
import "./scss/every_faq.scss";

const EveryFAQ = (props) => {
    let faqs = faqData[props.data];
    let columns = props.columns || 1;
    let faqLeft;
    let faqRight;
    const midpoint = Math.ceil(faqs.data.length / 2);

    switch (columns) {
        case 2:
            faqLeft = faqs.data.slice(0, midpoint);
            faqRight = faqs.data.slice(midpoint);
            break;
        case 1:
        default: faqLeft = faqs.data;
    }
    
    return (
        <Container className="max-width-lg mb-5"> 
            <Container>
                <Row>
                    <Col sm={12} md={faqRight && 6}>
                        {faqLeft.map((faq, index) => {
                            return (
                                <Accordion key={index} className="every-faq-accordion">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            {faq.question}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            {faq.answer}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                   
                            );
                        })}
                    </Col>   
                    {faqRight && 
                    <Col>
                        {faqRight.map((faq, index) => {
                            return (
                                <Accordion key={index} className="every-faq-accordion">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            {faq.question}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            {faq.answer}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                   
                            );
                        })}
                    </Col>
                    }      
                </Row>
            </Container>         
            
        </Container>
    );
};

export default EveryFAQ;