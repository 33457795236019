/**
 * Formats names with prefix, first, last, and suffix.
 *
 * @param {Object} title vet/staff info
 * @returns {string} returns concatenated string with prefix, first_name, last_name, suffix
 */

export default function formatDisplayName({
    first_name,
    last_name,
    prefix,
    suffix
}) {
    const pre = prefix ? `${prefix} ` : "";
    const first = first_name ? `${first_name} ` : "";
    const last = last_name ? `${last_name}` : "";
    const suff = suffix ? `, ${suffix}` : "";
    const name = `${pre}${first}${last}${suff}`.trim();
    return name;
}