/**
 * Formats form data to send in email templates.
 *
 * @param {Object} formData form data after submit
 * @returns {Object} form data formatted for email template (and eventually PDF or CSV)
 */

import templateType from "../enum/email_template_type";

const formatPhoneNumber = (phoneNumberString) => {
    if (phoneNumberString.length > 10) {
        return "+" + phoneNumberString;
    }
    const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return "(" + match[1] + ") " + match[2] + " " + match[3];
    }
    return null;
};

export default (formData, formType) => {
    let formattedData = formData;
    switch (formType) {
        case templateType.NEW_PATIENT:
            formattedData.formatted_address = 
        `${formData.address_line_one}${formData.address_line_two ? ", " + formData.address_line_two : "" }, ${formData.city_state} ${formData.postal_code}`; 
            formattedData.owner_phone = formatPhoneNumber(formData.owner_phone);
            formattedData.mobile_phone = formatPhoneNumber(formData.mobile_phone);
            formattedData.other_phone = formatPhoneNumber(formData.other_phone);
            break;
        case templateType.REQUEST_APPOINTMENT:
            formattedData.owner_phone = formatPhoneNumber(formData.owner_phone);
            break;
        case templateType.CONTACT_US:
            formattedData.visitor_phone = formatPhoneNumber(formData.visitor_phone);
            break;
        default: formattedData = formData; 
    } 
    return formattedData;
};