/**
 * Splits strings by separator and returns as an unformatted list
 *
 * @param {string} title job title with forward slash separator
 * @param {string} separator separator used to split title
 * @param {number} gap amount of space in between returned lines
 * @returns {BsPrefixRefForwardingComponent} returns Bootstrap Stack component
 */

import { Stack } from "react-bootstrap";

export default (title, separator="/", gap=1) => {
    let formattedTitle = title.split(separator);
    return (
        <Stack gap={gap}>
            {formattedTitle.map((item, index) => {
                return (
                    <div key={index}>{item}</div>
                );
            })}

        </Stack>            
    );
};