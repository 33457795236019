import React from "react";

import "./scss/every_svg.scss";

/**
 * EverySVG is a custom svg image implementation.
 * ALL icons SHOULD be the same color, so a default is provided, but a prop can be passed to change it if needed
 */
const EverySVG = (props) => {
    let { imageName, border=false, size="large", bgColor="neutral", color="tertiary", padding="" } = props;
    if (border) {
        border = "container-border";
    }

    return (
        <div className={`every-svg-container ${size} ${border} img-bg-${bgColor} ${padding}`}>
            <div className={`every-svg-img ${size} ${imageName} ${color}`}></div>
        </div>
    );
};

export default EverySVG;