/**
 * Google Place Autocomplete Widget
 * 
 *   Read more about the places widget here:
 *   https://developers.google.com/maps/documentation/javascript/reference/places-widget#Autocomplete
 * 
 *   Read more specifically about "fields" here:
 *   https://developers.google.com/maps/documentation/javascript/reference/places-service#PlaceResult
 * 
 *   Read more specifically about "types" here:
 *   https://developers.google.com/maps/documentation/javascript/place-autocomplete#constrain-place-types
 */
const useGooglePlaceAutoComplete = () => {

    /**
     * Initializes the Google AutoComplete Widget based on type requested.
     * @param {*} input : name of the input field the widget is being bound to on your form
     * @param {*} callback : function name to call when a place is selected
     * @returns : the bound HTML element with a listener
     */
    const initAutoComplete = async (input, callback, types = "address") => {
        let autoComplete =
            new window.google.maps.places.Autocomplete(input,
                {
                    // limit to North America for now
                    componentRestrictions: { country: ["us", "ca"] },
                    fields: ["address_component", "geometry"],         // never EVER use "ALL"
                    types: [types]
                }
            );
        autoComplete.addListener("place_changed", callback);

        return autoComplete;

    };

    /**
     * @param {*} autoComplete - the Place component selected from the Google Autocomplete widget
     * @returns : a parsed address (including street address) in JSON format 
     */
    const getPlace = async (autoComplete) => {

        const address = autoComplete.getPlace();
        
        let address_line_one, 
            locality, 
            adminArea1Short, 
            adminArea1Long, 
            countryShort,
            countryLong, 
            postalCode,
            geoLocation = "";

        // Get each component of the address from the place details,
        for (const component of address.address_components) {
            
            const componentType = component.types[0];

            if (componentType === "street_number") {
                address_line_one = component.long_name;
            }
            if (componentType === "route") {
                address_line_one = `${address_line_one} ${component.long_name}`;
            }
            if (componentType === "locality") {
                locality = component.long_name;
            }
            if (componentType === "administrative_area_level_1") {
                adminArea1Short = component.short_name;
                adminArea1Long = component.long_name;
            }
            if (componentType === "postal_code") {
                postalCode = component.long_name;
            }
            if (componentType === "postal_code_suffix") {
                postalCode = `${postalCode}-${component.long_name}`;
            }
            if (componentType === "country") {
                countryShort = component.short_name;
                countryLong = component.long_name;
            }
        }

        // return the geo location value
        if (address.geometry && address.geometry.location) {
            geoLocation = `${address.geometry.location.lat()}, ${address.geometry.location.lng()}`;
        }

        let resAddress = {
            "address_line_one": address_line_one || "",
            "locality": locality || "",
            "adminArea1Short": adminArea1Short || "",
            "adminArea1Long": adminArea1Long || "",
            "postalCode": postalCode || "",
            "countryShort": countryShort || "",
            "countryLong": countryLong || "",
            "geoLocation": geoLocation || ""
        };

        return resAddress;

    };

    return {
        initAutoComplete,
        getPlace
    };

};

export default useGooglePlaceAutoComplete;