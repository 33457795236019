import { useEffect } from "react";
import { useLocation } from "react-router";

const ScrollToTop = (props) => {
    const location = useLocation();

    const scrollUp = async () => {
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        scrollUp();
    }, [location]);

    return (
        <>
            {props.children}
        </>
    );
};

export default ScrollToTop;