import * as yup from "yup";
import regexPatterns from "../enum/regex_patterns";

const contactUsForm = yup
    .object()
    .shape({
        full_name: yup.string()
            .required("Please enter your full name."),
        email: yup.string()
            .required("Please enter a valid email address.")
            .matches(regexPatterns.EMAIL, "Please enter a valid email address.")
    });

const requestAppointmentForm = yup
    .object()
    .shape({
        full_name: yup.string()
            .required("Please enter your full name."),
        email: yup.string()
            .required("Please enter a valid email address.")
            .matches(regexPatterns.EMAIL, "Please enter a valid email address."),
        phone: yup.string()
            .required("Please enter a valid phone number.")
            .matches(regexPatterns.PHONE, "Please enter a valid phone number."),
        pet_name: yup.string().required("Please enter your pet's name."),
        pet_species: yup.string()
            .typeError("Please select your pet's species.")
            .required("Please select your pet's species."),
        pet_breed: yup.string().required("Please enter your pet's breed."),
        appointment_type: yup.string().required("Please select the type of appointment you would like to book."),
        message: yup.string().required("Please tell us more about the reason for your visit."),
        preferred_day: yup.array().required("Please select at least one day that will work for you.").typeError("Please select at least one day that will work for you."),
        preferred_time: yup.array().required("Please select at least one time that will work for you.").typeError("Please select at least one time that will work for you.")
        
    });    

const newPatientForm = yup
    .object()
    .shape({
        full_name: yup.string()
            .required("Please enter your full name."),
        full_name_other: yup.string().notRequired(),
        email: yup.string()
            .required("Please enter a valid email address.")
            .matches(regexPatterns.EMAIL, "Please enter a valid email address."),
        phone: yup.string()
            .required("Please enter a valid phone number.")
            .matches(regexPatterns.PHONE, "Please enter a valid phone number."),
        phone_mobile: yup.string().when("phone_mobile", (val, schema) => {
            if (val?.length > 0) { 
                return yup.string().matches(regexPatterns.PHONE, "Please enter a valid phone number.").required();
            } else { 
                return yup.string().notRequired();
            }
        }),
        phone_other: yup.string().when("phone_other", (val, schema) => {
            if (val?.length > 0) {  
                return yup.string().matches(regexPatterns.PHONE, "Please enter a valid phone number.").required();
            } else { 
                return yup.string().notRequired();
            }
        }),
        address_line_one: yup.string().required("Please enter an address."),
        address_line_two: yup.string().notRequired(),
        city_state: yup.string().required("Please select a city and state."),
        postal_code: yup.string().required("Please enter a zip code."),
        contact_method: yup.string()
            .typeError("Please select your preferred method of communication.")
            .required("Please select your preferred method of communication."),
        referral_source: yup.string().notRequired(),
        pet_name: yup.string().required("Please enter your pet's name."),
        pet_birthdate: yup.string().required("Please enter your pet's birthday."),
        pet_species: yup.string()
            .typeError("Please select your pet's species.")
            .required("Please select your pet's species."),
        pet_gender: yup.string()
            .typeError("Please select your pet's gender.")
            .required("Please select your pet's gender."),
        spay_neuter: yup.string()
            .typeError("Please select one.")
            .required("Please select one."),
        pet_breed: yup.string().required("Please enter your pet's breed."),
        pet_color: yup.string().notRequired(),
        pet_medications: yup.string().notRequired(),
        previous_vet: yup.string().notRequired(),
        last_vaccination_date: yup.string().notRequired(),
        consent_agree: yup.string()
            .typeError("Please read and accept the Terms of Treatment and Payment.")
            .required("Please read and accept the Terms of Treatment and Payment."),
        consent_signature: yup.string().required("Please sign this form by typing your full name."),
        consent_date: yup.string().required("Please enter today's date.")
        

    },
    [
        ["phone_mobile", "phone_mobile"],
        ["phone_other", "phone_other"]
    ]);

const FormsSchema = { 
    contactUsForm,
    requestAppointmentForm,
    newPatientForm
};

export default FormsSchema;