import React from "react";
import { Container, Col, Row } from "react-bootstrap";

import "./scss/every_subhero.scss";

/**
 * EverySubHero is a responsive two column custom banner with header text on the left and paragraph text on the right divided by and accent color.
 */
const EverySubHero = (props) => {
    let { title, subpageHero=false, text, image, bgColor="", divider=true, spacerTop, spacerBottom } = props;


    return (
        <Container fluid className={`sub-hero-bg-container ${bgColor}`}>
            {spacerTop && <div className="spacer wave-top"></div>}
            <Container className="my-5">
                <Row className="every-sub-hero-row">
                    <Col xs={12} md={divider ? 4 :  6} className="every-sub-hero-title" >
                        {subpageHero ? <h1>{title}</h1> : <h2>{title}</h2>}
                    </Col>
                    {divider && <Col xs={12} md={1} className="every-sub-hero-divider"><>&nbsp;</></Col>}
                    <Col xs={12} md={6}>
                        {text && <p className="mb-2">{text}</p>}
                        {image && <div style={{backgroundImage: `url(${image})`}} className="every-sub-hero-image"/>}
                    </Col>
                </Row>
            </Container>
            {spacerBottom && <div className="spacer wave-bottom"></div>}
        </Container>
    );
};

export default EverySubHero;