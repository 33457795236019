// Send message via EMailJS > SendGrid
import emailjs from "@emailjs/browser";
import templateType from "../enum/email_template_type";
import formatFormData from "../util/formatFormData";

const useMessageService = () => {

    const determineDynamicData = async (messageData) => {

        let noDynamicData = { no_data : null};

        let websiteContactUsData = {
            recipient_name : process.env.REACT_APP_EMAILJS_TO_NAME,
            recipient_email : process.env.REACT_APP_EMAILJS_TO_EMAIL,
            recipient_org_name : process.env.REACT_APP_EMAILJS_TO_ORG_NAME,
            visitor_name : messageData.visitorName,
            visitor_email : messageData.visitorEmail,
            visitor_phone : messageData.visitorPhone,
            visitor_message : messageData.visitorMessage,
            "g-recaptcha-response" : messageData.recaptchaToken
        };

        let websiteAppointmentRequest = {
            recipient_name : process.env.REACT_APP_EMAILJS_TO_NAME,
            recipient_email : process.env.REACT_APP_EMAILJS_TO_EMAIL,
            recipient_org_name : process.env.REACT_APP_EMAILJS_TO_ORG_NAME,
            full_name_owner : messageData.ownerName,
            owner_email : messageData.ownerEmail,
            owner_phone : messageData.ownerPhone,
            formatted_owner_phone: "",
            pet_name : messageData.petName,
            pet_species : messageData.petSpecies,
            pet_breed : messageData.petBreed,
            appointment_type: messageData.appointmentType,
            appointment_message: messageData.message,
            preferred_day: messageData.preferredDay,
            preferred_time: messageData.preferredTime,
            "g-recaptcha-response" : messageData.recaptchaToken
        };

        let websiteNewPatientData = {
            recipient_name : process.env.REACT_APP_EMAILJS_TO_NAME,
            recipient_email : process.env.REACT_APP_EMAILJS_TO_EMAIL,
            recipient_org_name : process.env.REACT_APP_EMAILJS_TO_ORG_NAME,
            full_name_owner : messageData.ownerName,
            full_name_other: messageData.ownerNameOther,
            owner_email : messageData.ownerEmail,
            owner_phone : messageData.ownerPhone,
            mobile_phone : messageData.ownerPhoneMobile,
            other_phone : messageData.ownerPhoneOther,
            formatted_address : "",
            formatted_owner_phone: "",
            formatted_mobile_phone: "",
            formatted_other_phone: "",
            address_line_one : messageData.ownerAddressLineOne,
            address_line_two : messageData.ownerAddressLineTwo,
            city_state : messageData.ownerCityState,
            postal_code : messageData.ownerPostalCode,
            contact_method : messageData.ownerContactMethod,
            referral_source : messageData.ownerReferralSource,
            pet_name : messageData.petName,
            pet_birthdate : messageData.petBirthdate,
            pet_species : messageData.petSpecies,
            pet_gender : messageData.petGender,
            spay_neuter : messageData.petSpayNeuter,
            pet_breed : messageData.petBreed,
            pet_color : messageData.petColor,
            pet_medications : messageData.petMedications,
            previous_vet : messageData.petPreviousVet,
            last_vaccination_date : messageData.petLastVaccinationDate,
            consent_agree : messageData.consentAgree,
            consent_signature : messageData.consentSignature,
            consent_date : messageData.consentDate,
            "g-recaptcha-response" : messageData.recaptchaToken
        };
            
        let dynamicTemplateData = noDynamicData;

        if (messageData.templateName === templateType.CONTACT_US) { 
            dynamicTemplateData = formatFormData(websiteContactUsData, messageData.templateName);
        }
        if (messageData.templateName === templateType.NEW_PATIENT) { 
            dynamicTemplateData = formatFormData(websiteNewPatientData, messageData.templateName);
        }
        if (messageData.templateName === templateType.REQUEST_APPOINTMENT) {
            dynamicTemplateData = formatFormData(websiteAppointmentRequest, messageData.templateName);
        }

        return dynamicTemplateData;

    };

    const sendMessage = async (data) => {
        try {
            let messageData = data;
            let userId = process.env.REACT_APP_EMAILJS_USER_ID;
            let serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;

            let templateId = "";
            switch (messageData.templateName) {
                case templateType.CONTACT_US:
                    templateId = process.env.REACT_APP_EMAILJS_CONTACT_US_TEMPLATE_ID; 
                    break;
                case templateType.NEW_PATIENT:
                    templateId = process.env.REACT_APP_EMAILJS_NEW_PATIENT_TEMPLATE_ID;
                    break;
                case templateType.REQUEST_APPOINTMENT:
                    templateId = process.env.REACT_APP_EMAILJS_REQUEST_APPT_TEMPLATE_ID;
                    break;
                default: templateId = process.env.REACT_APP_EMAILJS_CONTACT_US_TEMPLATE_ID; 

            }

            let dynamicTemplateData = await determineDynamicData(messageData);

            let res = await emailjs.send(
                serviceId, 
                templateId, 
                dynamicTemplateData, 
                userId
            );

            return res;

        } catch (e) {
            return (e.message);
        }

    };

    return {
        sendMessage
    };
};

export default useMessageService;